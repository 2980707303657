import { L as LIST_COUNTRY } from "./country.js";
import { n as normalizeComponent } from "./vueComponentNormalizer.js";
var render$1 = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("list-composer", { attrs: { "title": "Planned Orders", "has-filter-inactive": false, "has-active": false, "has-action": false, "search-by": "ItemCode", "ph-search-by": "Search by Item Code" }, scopedSlots: _vm._u([{ key: "customFilter", fn: function() {
    return [_c("div", { staticClass: "po-filter d-flex align-items-center" }, [_c("span", { staticClass: "mr-2 mb-0" }, [_vm._v(" Country ")]), _c("select-input", { staticClass: "select-filter", attrs: { "data-source": _vm.listCountry, "source": "code", "source-label": "countryName", "default-active-first-option": "" }, on: { "change": _vm.onCountryChange } })], 1)];
  }, proxy: true }]) }, [_c("text-field", { key: "CountryCode", attrs: { "data-index": "countryCode", "title": "Country", "parse": _vm.parseCountry, "sorter": true } }), _c("text-field", { key: "ItemCode", attrs: { "data-index": "itemCode", "title": "Item", "sorter": true } }), _c("text-field", { key: "ItemDescription", attrs: { "data-index": "itemDescription", "title": "Description", "sorter": true } }), _c("text-field", { key: "Abc", attrs: { "data-index": "abc", "title": "ABC" } }), _c("text-field", { key: "Type", attrs: { "data-index": "type", "title": "Type" } }), _c("text-field", { key: "FactoryName", attrs: { "data-index": "factoryName", "title": "Factory" } }), _c("text-field", { key: "ReleaseDate", attrs: { "data-index": "releaseDate", "title": "Release Date", "parse": _vm.parseDate, "sorter": true } }), _c("text-field", { key: "DueDate", attrs: { "data-index": "dueDate", "title": "Due Date", "parse": _vm.parseDate, "sorter": true } }), _c("text-field", { key: "PlanType", attrs: { "data-index": "planType", "title": "Plan Type", "sorter": true } }), _c("text-field", { key: "Quantity", attrs: { "data-index": "quantity", "title": "Quantity", "sorter": true } }), _c("text-field", { key: "Cost", attrs: { "data-index": "cost", "title": "Cost", "sorter": true } }), _c("text-field", { key: "CostUs", attrs: { "data-index": "costUs", "title": "Cost US", "sorter": true } })], 1);
};
var staticRenderFns$1 = [];
var PlannedOrders_vue_vue_type_style_index_0_scoped_true_lang = /* @__PURE__ */ (() => ".po-filter .select-filter[data-v-4bb86f22]{width:100px}\n")();
const __vue2_script$1 = {
  name: "PlannedOrders",
  inject: ["crud", "facility"],
  data() {
    return {
      listCountry: LIST_COUNTRY
    };
  },
  async created() {
    await this.crud.setQueryString("facility", this.facility.value);
  },
  async beforeDestroy() {
    await this.crud.deleteQueryStrings();
  },
  methods: {
    parseCountry(value) {
      return value.toUpperCase();
    },
    parseDate(value) {
      return this.$moment(value).format("DD/MM/YYYY");
    },
    async onCountryChange(value) {
      await this.crud.setQueryString("countryCode", value);
      this.crud.fetchList();
    }
  }
};
const __cssModules$1 = {};
var __component__$1 = /* @__PURE__ */ normalizeComponent(__vue2_script$1, render$1, staticRenderFns$1, false, __vue2_injectStyles$1, "4bb86f22", null, null);
function __vue2_injectStyles$1(context) {
  for (let o in __cssModules$1) {
    this[o] = __cssModules$1[o];
  }
}
var PlannedOrders = /* @__PURE__ */ function() {
  return __component__$1.exports;
}();
var render = function() {
  var _vm = this;
  var _h = _vm.$createElement;
  var _c = _vm._self._c || _h;
  return _c("div", [_c("bread-crumb", { attrs: { "items": _vm.menuItems } }), _c("resource", { attrs: { "name": "line-performance.ctps.plannedorder", "api-url": _vm.apiUrl } }, [_c("planned-orders")], 1)], 1);
};
var staticRenderFns = [];
var index_vue_vue_type_style_index_0_lang = /* @__PURE__ */ (() => "")();
const apiUrl = "#{VUE_APP_API_URL}#";
const __vue2_script = {
  components: { PlannedOrders },
  data() {
    return {
      apiUrl,
      menuItems: [
        {
          key: "ctp",
          title: "CTP",
          path: ""
        },
        {
          key: "central-database",
          title: "Central Database",
          path: ""
        },
        {
          key: "planned-orders",
          title: "Planned Orders",
          path: "/line-performance/planned-orders"
        }
      ]
    };
  }
};
const __cssModules = {};
var __component__ = /* @__PURE__ */ normalizeComponent(__vue2_script, render, staticRenderFns, false, __vue2_injectStyles, null, null, null);
function __vue2_injectStyles(context) {
  for (let o in __cssModules) {
    this[o] = __cssModules[o];
  }
}
var index = /* @__PURE__ */ function() {
  return __component__.exports;
}();
export { index as default };
